import { Utils } from '../utils';
import AppIframe from './appIframe';

export const PLACEMENT_ID = {
    PRODUCT_LIST: 'PRODUKTLISTE',
    TEASER: 'KATEGORIESEITE',
    LOGOUT: 'LOGOUTSEITE',
    STOREFRONT: 'STOREFRONT',
    SEARCH_RESULT: 'SUCHERGEBNISSEITE',
    PDP: 'PDP',
};

const SIZES = {
    SKYSCRAPER: '160x600',
    WIDE_BOTTOMBAR: '600x300,600x150,600x100,640x300',
    NARROW_BOTTOMBAR: '300x150,300x75,300x50,320x150',
};

const removeNulls = (obj) => {
    const copy = Object.assign({}, obj);
    Object.keys(copy).filter((key) => copy[key] === null && delete copy[key]);
    return copy;
};

const createQueryString = (parameters) => {
    return new URLSearchParams(removeNulls(parameters)).toString();
};

const utils = new Utils();

export default class AdvertService {
    constructor(trackingApi, contextInformationService) {
        this.trackingApi = trackingApi;
        this.contextInformationService = contextInformationService;
    }

    _isAdvertAlreadyDisplayed(element) {
        return !!element.querySelector('iframe');
    }

    _queryDataAtributeFromAdContainer(deviceType, dataAttribute) {
        if (deviceType === 'mobile') {
            const bottomBar =
                document.getElementById('wato_av_bottom') ||
                document.getElementById('san_av_bottom');
            return bottomBar.getAttribute(dataAttribute);
        } else {
            return document
                .getElementById('wato_onsite_advert')
                .getAttribute(dataAttribute);
        }
    }

    _scrapePlacementId() {
        const avContentDiv = document.getElementById('avContent');
        if (this._hasClass(avContentDiv, 'js_av_productList')) {
            return PLACEMENT_ID.PRODUCT_LIST;
        } else if (this._hasClass(avContentDiv, 'js_av_teaser')) {
            return PLACEMENT_ID.TEASER;
        } else if (this._hasClass(avContentDiv, 'user_logout')) {
            return PLACEMENT_ID.LOGOUT;
        } else if (this._hasClass(avContentDiv, 'storeFrontAV')) {
            return PLACEMENT_ID.STOREFRONT;
        } else if (
            this._hasClass(avContentDiv, 'js_av_searchResult') ||
            document
                .getElementsByTagName('html')[0]
                .getAttribute('data-pagecluster') === 'Suchergebnisseite'
        ) {
            return PLACEMENT_ID.SEARCH_RESULT;
        } else if (document.getElementById('avJson')) {
            return PLACEMENT_ID.PDP;
        }
        return null;
    }

    _getPlacementId(deviceType) {
        const prefix =
            deviceType === 'mobile' ? 'MobileContentAd_' : 'SKYSCRAPER_';
        const placementId = this._queryDataAtributeFromAdContainer(
            deviceType,
            'data-placement-id'
        );
        if (placementId) {
            return placementId;
        } else {
            if (this._scrapePlacementId()) {
                return prefix + this._scrapePlacementId();
            }
            return null;
        }
    }

    _hasClass(e, className) {
        return e && e.classList.contains(className);
    }

    renderSkyscraper() {
        const deviceType = 'desktop';
        const anchor = document.getElementById('wato_onsite_advert');
        if (!anchor) {
            return;
        }
        if (anchor && this._isAdvertAlreadyDisplayed(anchor)) {
            return;
        }

        const placementId = this._getPlacementId(deviceType);

        let contextualInformation = {
            produktbasisklasse: this._queryDataAtributeFromAdContainer(
                deviceType,
                'data-produktbasisklasse'
            ),
            brandgroup: this._queryDataAtributeFromAdContainer(
                deviceType,
                'data-brand-group'
            ),
        };
        // try to get contextualInformation by WATO first, if not both information exist, try to get it from sda
        if (
            !contextualInformation.brandgroup ||
            !contextualInformation.produktbasisklasse
        ) {
            contextualInformation = this.contextInformationService.retrieve();
        }

        const queryString = createQueryString({
            placementId: placementId,
            sizes: SIZES.SKYSCRAPER,
            pageUrl: location.href,
            ...contextualInformation,
        });

        let src = `/wato-onsite/advertisement?${queryString}`;
        const appIframe = this.createAppIframe(src, anchor, placementId, true);
        appIframe.prependTo(anchor);
    }

    createAppIframe(src, anchor, placementId, isSkyScraper) {
        const appIframe = new AppIframe(src, isSkyScraper);
        appIframe.onFailure = this.getOnFailureCallback(
            anchor,
            appIframe,
            placementId
        );
        appIframe.onSuccess = (iframeRef) => {
            if (iframeRef.contentDocument) {
                const campaignId = this.getCampaignIdFrom(iframeRef);
                this.trackView(placementId, campaignId);
                const dsaInfo = this.getDsaInfo(iframeRef);
                const sheet = this.buildSheet(dsaInfo);
                this.attachOpenSheetOnClickListenerToAnchor(anchor, sheet);
                this.showRelatedDsaTag(iframeRef);
            }
        };
        return appIframe;
    }

    getDsaInfo(iframeRef) {
        const sponsor =
            iframeRef.contentWindow.document.body.children[0].dataset.sponsor;
        const advertiser =
            iframeRef.contentWindow.document.body.children[0].dataset
                .advertiser;

        return {
            sponsor,
            advertiser,
        };
    }

    showRelatedDsaTag(iframeRef) {
        iframeRef.nextElementSibling.style.display = '';
    }

    buildSheet(dsaInfo) {
        return new window.o_global.pali.sheetBuilder({
            title: 'Warum sehe ich diese Fläche?',
            content: `
        <p class="pl_copy100">
            Hier handelt es sich um eine gesponserte Fläche. Das sind Werbeanzeigen, die
            von unseren Lieferanten und Händlern finanziert werden.
        </p>
        <p class="pl_copy100 pl_mb150">
            Wir achten darauf, dass diese Anzeigen zu deinem Interesse bzw. deiner Suche
            passen.
        </p>
        <p class="pl_headline100">Über den Werbetreibenden</p>
        <ul class="pl_list--unordered pl_copy100">
            <li class="pl_mb0"><span class="pl_headline50">Name des Werbetreibenden:</span> ${dsaInfo.advertiser}</li>
            <li><span class="pl_headline50">Wer hat für diese Anzeige bezahlt:</span> ${dsaInfo.sponsor}</li>
        </ul>`,
        });
    }

    attachOpenSheetOnClickListenerToAnchor(anchor, paliSheet) {
        anchor
            .querySelector('.wato-sda-dsa-tag__container')
            .addEventListener('click', () => paliSheet.open());
    }

    renderBottomBarByLazyLoading(anchor) {
        if (!window.IntersectionObserver) {
            //does exist only in browser not tests
            return;
        }
        const options = {
            root: null, // we're observing relative to the viewport
            rootMargin: '0px',
            threshold: 0,
        };
        // eslint-disable-next-line no-undef
        const observer = new IntersectionObserver((event, observer) => {
            const entry = event[0];
            if (entry.isIntersecting) {
                utils.debugLog('Banner in viewport, lazy loading triggered');
                this.renderBottombar(anchor);
                observer.unobserve(entry.target);
            }
        }, options);
        observer.observe(anchor);
    }

    renderMobile() {
        const anchor =
            document.getElementById('wato_av_bottom') ||
            document.getElementById('san_av_bottom');
        if (!anchor) {
            utils.debugLog('No anchor found');
            return;
        }
        if (this._isAdvertAlreadyDisplayed(anchor)) {
            utils.debugLog('Advert already displayed');
            return;
        }

        this.renderBottomBarByLazyLoading(anchor);
    }

    renderBottombar(anchor) {
        const deviceType = 'mobile';
        const placementId = this._getPlacementId(deviceType);

        let contextualInformation = {
            produktbasisklasse: this._queryDataAtributeFromAdContainer(
                deviceType,
                'data-produktbasisklasse'
            ),
            brandgroup: this._queryDataAtributeFromAdContainer(
                deviceType,
                'data-brand-group'
            ),
        };
        // try to get contextualInformation by WATO first, if not both information exist, try to get it from sda
        if (
            !contextualInformation.brandgroup ||
            !contextualInformation.produktbasisklasse
        ) {
            contextualInformation = this.contextInformationService.retrieve();
        }

        const queryString = createQueryString({
            placementId: placementId,
            sizes: this.getAdSizesToRequest(),
            pageUrl: location.href,
            ...contextualInformation,
        });

        let src = `/wato-onsite/advertisement?${queryString}`;
        const appIframe = this.createAppIframe(src, anchor, placementId, false);
        appIframe.prependTo(anchor.querySelector('.ad-container'));
    }

    trackView(placementId, campaignId) {
        const payload = {
            wato_AdActivity: 'view',
            wato_AdPosition: placementId,
            wato_AdContent: campaignId,
        };
        this.trackingApi.sendMergeRequest(payload);
    }

    getCampaignIdFrom(iframeRef) {
        const src =
            iframeRef.contentDocument.getElementsByTagName('img')[0]?.src;
        if (src) {
            return new URL(src).searchParams.get('campaignId');
        }
        return '';
    }

    getOnFailureCallback(anchor, appIframe, placementId) {
        return () => {
            appIframe.detachFrom(anchor);
            const payload = {
                wato_AdActivity: 'false',
                wato_AdPosition: placementId,
            };
            this.trackingApi.sendMergeRequest(payload);
        };
    }

    getAdSizesToRequest() {
        const viewPortWidth = utils.getWindowInnerWidth();

        return viewPortWidth < 640
            ? SIZES.NARROW_BOTTOMBAR
            : SIZES.WIDE_BOTTOMBAR;
    }

    renderAd(deviceType) {
        deviceType === 'mobile' ? this.renderMobile() : this.renderSkyscraper();
    }
}
